import React from 'react';


function Disabled() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-928.000000, -377.000000)" fill="#D0021B" fillRule="nonzero">
          <g transform="translate(231.000000, 110.000000)">
            <g transform="translate(662.000000, 136.000000)">
              <g transform="translate(10.000000, 116.000000)">
                <path d="M39.6428571,23.8236607 C39.6428571,22.6257381 39.3191997,21.5282788 38.671875,20.53125 L30.2566964,28.9352679 C31.2760468,29.5974735 32.3809464,29.9285714 33.5714286,29.9285714 C34.3973256,29.9285714 35.184148,29.7667427 35.9319196,29.4430804 C36.6796912,29.119418 37.3251461,28.6860146 37.8683036,28.1428571 C38.411461,27.5996997 38.8430044,26.9505246 39.1629464,26.1953125 C39.4828885,25.4401004 39.6428571,24.6495577 39.6428571,23.8236607 Z M28.4933036,27.1607143 L36.9196429,18.7455357 C35.9151735,18.068449 34.7991133,17.7299107 33.5714286,17.7299107 C32.4702326,17.7299107 31.4546177,18.0014854 30.5245536,18.5446429 C29.5944894,19.0878003 28.8578896,19.8281203 28.3147321,20.765625 C27.7715747,21.7031297 27.5,22.7224647 27.5,23.8236607 C27.5,25.0290239 27.8310979,26.141364 28.4933036,27.1607143 Z M41.4620536,20.4866071 C41.9159249,21.54316 42.1428571,22.6555001 42.1428571,23.8236607 C42.1428571,24.9918213 41.9159249,26.1078816 41.4620536,27.171875 C41.0081823,28.2358684 40.3999294,29.1510378 39.6372768,29.9174107 C38.8746242,30.6837836 37.9631749,31.2938965 36.9029018,31.7477679 C35.8426286,32.2016392 34.7321487,32.4285714 33.5714286,32.4285714 C32.4107085,32.4285714 31.3002285,32.2016392 30.2399554,31.7477679 C29.1796822,31.2938965 28.268233,30.6837836 27.5055804,29.9174107 C26.7429277,29.1510378 26.1346749,28.2358684 25.6808036,27.171875 C25.2269323,26.1078816 25,24.9918213 25,23.8236607 C25,22.6555001 25.2269323,21.5412999 25.6808036,20.4810268 C26.1346749,19.4207536 26.7429277,18.5074443 27.5055804,17.7410714 C28.268233,16.9746985 29.1796822,16.3645856 30.2399554,15.9107143 C31.3002285,15.456843 32.4107085,15.2299107 33.5714286,15.2299107 C34.7321487,15.2299107 35.8426286,15.456843 36.9029018,15.9107143 C37.9631749,16.3645856 38.8746242,16.9746985 39.6372768,17.7410714 C40.3999294,18.5074443 41.0081823,19.4226137 41.4620536,20.4866071 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Disabled;
