import React from 'react';

function Collection() {
  return (
    <svg width="13px" height="11px" viewBox="0 0 13 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="03-Nav-Rollover-States" transform="translate(-6.000000, -101.000000)" fill="#AEAEAE">
          <g id="Group-12" transform="translate(0.000000, 100.000000)">
            <g id="Group-11" transform="translate(6.000000, 1.000000)">
              <path d="M2.78571761,9.07142746 C2.78571761,8.30245333 2.16183293,7.67856866 1.3928588,7.67856866 C0.623884672,7.67856866 0,8.30245333 0,9.07142746 C0,9.8404016 0.623884672,10.4642863 1.3928588,10.4642863 C2.16183293,10.4642863 2.78571761,9.8404016 2.78571761,9.07142746 Z M2.78571761,5.35713732 C2.78571761,4.58816319 2.16183293,3.96427852 1.3928588,3.96427852 C0.623884672,3.96427852 0,4.58816319 0,5.35713732 C0,6.12611145 0.623884672,6.74999613 1.3928588,6.74999613 C2.16183293,6.74999613 2.78571761,6.12611145 2.78571761,5.35713732 Z M13.0000155,8.37499806 C13.0000155,8.25167202 12.8911984,8.14285493 12.7678724,8.14285493 L3.94643328,8.14285493 C3.82310724,8.14285493 3.71429014,8.25167202 3.71429014,8.37499806 L3.71429014,9.76785687 C3.71429014,9.89118291 3.82310724,10 3.94643328,10 L12.7678724,10 C12.8911984,10 13.0000155,9.89118291 13.0000155,9.76785687 L13.0000155,8.37499806 Z M2.78571761,1.64284718 C2.78571761,0.873873049 2.16183293,0.249988377 1.3928588,0.249988377 C0.623884672,0.249988377 0,0.873873049 0,1.64284718 C0,2.41182131 0.623884672,3.03570598 1.3928588,3.03570598 C2.16183293,3.03570598 2.78571761,2.41182131 2.78571761,1.64284718 Z M13.0000155,4.66070792 C13.0000155,4.53738188 12.8911984,4.42856479 12.7678724,4.42856479 L3.94643328,4.42856479 C3.82310724,4.42856479 3.71429014,4.53738188 3.71429014,4.66070792 L3.71429014,6.05356672 C3.71429014,6.17689276 3.82310724,6.28570986 3.94643328,6.28570986 L12.7678724,6.28570986 C12.8911984,6.28570986 13.0000155,6.17689276 13.0000155,6.05356672 L13.0000155,4.66070792 Z M13.0000155,0.946417779 C13.0000155,0.823091739 12.8911984,0.714274645 12.7678724,0.714274645 L3.94643328,0.714274645 C3.82310724,0.714274645 3.71429014,0.823091739 3.71429014,0.946417779 L3.71429014,2.33927658 C3.71429014,2.46260262 3.82310724,2.57141972 3.94643328,2.57141972 L12.7678724,2.57141972 C12.8911984,2.57141972 13.0000155,2.46260262 13.0000155,2.33927658 L13.0000155,0.946417779 Z" id="Collection_bookmarks_ico" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Collection;
