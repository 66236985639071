import React from 'react';


function TrashIcon() {
  return (
    <svg width="8px" height="11px" viewBox="0 0 18 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-94.000000, -36.000000)" fill="#7B7B7B" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(76.000000, 18.000000)">
          <path d="M18.25,21.7812839 L18.25,20.6875365 C18.25,20.4271203 18.3411454,20.205767 18.5234368,20.0234756 C18.7057283,19.8411842 18.9270815,19.7500387 19.1874978,19.7500387 L23.5624873,19.7500387 L23.914049,19.007853 C23.9921738,18.8516034 24.109361,18.7279056 24.2656107,18.6367601 C24.4218603,18.5456147 24.5911305,18.5000417 24.7734219,18.5000417 L29.2265363,18.5000417 C29.4088278,18.5000417 29.578098,18.5456147 29.7343476,18.6367601 C29.8905972,18.7279056 30.0077845,18.8516034 30.0859093,19.007853 L30.4374709,19.7500387 L34.8124605,19.7500387 C35.0728768,19.7500387 35.29423,19.8411842 35.4765214,20.0234756 C35.6588129,20.205767 35.7499583,20.4271203 35.7499583,20.6875365 L35.7499583,21.7812839 C35.7499583,21.9114917 35.7043853,22.0221689 35.6132399,22.1133144 C35.5220944,22.2044598 35.4114172,22.2500328 35.2812094,22.2500328 L18.7187489,22.2500328 C18.5885411,22.2500328 18.4778638,22.2044598 18.3867184,22.1133144 C18.295573,22.0221689 18.25,21.9114917 18.25,21.7812839 Z M34.4608989,24.0078411 L33.6796507,36.7421857 C33.6536089,37.2369764 33.4582969,37.6536417 33.0937146,37.9921828 C32.7291323,38.3307238 32.2994459,38.499994 31.8046552,38.499994 L22.1953031,38.499994 C21.7005124,38.499994 21.2708259,38.3307238 20.9062437,37.9921828 C20.5416614,37.6536417 20.3463494,37.2369764 20.3203076,36.7421857 L19.5390594,24.0078411 C19.5390594,23.8776333 19.5846324,23.760446 19.6757779,23.6562794 C19.7669233,23.5521128 19.8776005,23.5000298 20.0078083,23.5000298 L33.99215,23.5000298 C34.1223578,23.5000298 34.233035,23.5521128 34.3241804,23.6562794 C34.4153258,23.760446 34.4608989,23.8776333 34.4608989,24.0078411 Z" />
        </g>
      </g>
    </svg>
  );
}

export default TrashIcon;
