import React from 'react';

function Mail() {
  return (
    <svg className="contact" width="88px" height="88px" viewBox="0 0 88 88" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Icon" fill="#337ab7">
          <path d="M76.25,34.625 C75.1785714,35.8035714 73.9642857,36.8392857 72.6785714,37.7321429 C66.7142857,41.7678571 60.7142857,45.8392857 54.8928571,50.0535714 C51.8928571,52.2678571 48.1785714,54.9821429 44.2857143,54.9821429 L44.2142857,54.9821429 C40.3214286,54.9821429 36.6071429,52.2678571 33.6071429,50.0535714 C27.7857143,45.8035714 21.7857143,41.7678571 15.8571429,37.7321429 C14.5357143,36.8392857 13.3214286,35.8035714 12.25,34.625 L12.25,62.9821429 C12.25,66.125 14.8214286,68.6964286 17.9642857,68.6964286 L70.5357143,68.6964286 C73.6785714,68.6964286 76.25,66.125 76.25,62.9821429 L76.25,34.625 Z M76.25,24.125 C76.25,20.9821429 73.6428571,18.4107143 70.5357143,18.4107143 L17.9642857,18.4107143 C14.1428571,18.4107143 12.25,21.4107143 12.25,24.9107143 C12.25,28.1607143 15.8571429,32.1964286 18.3928571,33.9107143 C23.9285714,37.7678571 29.5357143,41.625 35.0714286,45.5178571 C37.3928571,47.125 41.3214286,50.4107143 44.2142857,50.4107143 L44.2857143,50.4107143 C47.1785714,50.4107143 51.1071429,47.125 53.4285714,45.5178571 C58.9642857,41.625 64.5714286,37.7678571 70.1428571,33.9107143 C73.2857143,31.7321429 76.25,28.125 76.25,24.125 Z" />
        </g>
      </g>
    </svg>
  );
}

export default Mail;
