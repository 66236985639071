import React from 'react';


function PencilIcon() {
  return (
    <svg width="8px" height="8px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1403.000000, -189.000000)" fill="#3E3E3E">
          <path d="M1431.24993,200.750039 C1431.08327,200.916705 1430.89577,201.000038 1430.68743,201.000038 C1430.4791,201.000038 1430.31243,200.916705 1430.18744,200.750039 L1423.24995,193.812555 C1423.08329,193.687556 1422.99995,193.520889 1422.99995,193.312556 C1422.99995,193.104224 1423.08329,192.916724 1423.24995,192.750058 L1426.12494,189.875065 C1426.70828,189.291733 1427.41661,189.000067 1428.24994,189.000067 C1429.08327,189.000067 1429.7916,189.291733 1430.37493,189.875065 L1434.12493,193.625056 C1434.70826,194.208387 1434.99992,194.91672 1434.99992,195.750051 C1434.99992,196.583382 1434.70826,197.291714 1434.12493,197.875046 L1431.24993,200.750039 Z M1420.74996,195.250052 C1420.91662,195.083385 1421.10412,195.000052 1421.31246,195.000052 C1421.52079,195.000052 1421.68746,195.083385 1421.81246,195.250052 L1428.74994,202.187535 C1428.91661,202.312535 1428.99994,202.479202 1428.99994,202.687534 C1428.99994,202.895867 1428.91661,203.083366 1428.74994,203.250033 L1412.37498,219.624994 L1404.75,220.99999 C1404.25,221.083323 1403.8125,220.937491 1403.4375,220.562492 C1403.0625,220.187492 1402.91667,219.749993 1403,219.249995 L1404.375,211.625013 L1420.74996,195.250052 Z M1410.74998,210.250016 C1410.91665,210.416683 1411.12498,210.500015 1411.37498,210.500015 C1411.62498,210.500015 1411.83331,210.416683 1411.99998,210.250016 L1421.62496,200.625039 C1421.79162,200.458372 1421.87495,200.25004 1421.87495,200.000041 C1421.87495,199.750041 1421.79162,199.541709 1421.62496,199.375042 C1421.45829,199.208375 1421.24996,199.125043 1420.99996,199.125043 C1420.74996,199.125043 1420.54163,199.208375 1420.37496,199.375042 L1410.74998,209.000019 C1410.58331,209.166686 1410.49998,209.375018 1410.49998,209.625018 C1410.49998,209.875017 1410.58331,210.08335 1410.74998,210.250016 Z M1408.49999,215.500004 L1408.49999,212.500011 L1406.24999,212.500011 L1405.49999,216.500001 L1407.49999,218.499996 L1411.49998,217.749998 L1411.49998,215.500004 L1408.49999,215.500004 Z" />
        </g>
      </g>
    </svg>
  );
}

export default PencilIcon;
