import React from 'react';


function Carot(props) {
  const { flip } = props;

  return (
    <svg width="11px" height="7px" viewBox="0 0 11 7" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotateZ(${flip ? 0 : 180}deg)` }}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.000000, -4.000000)" fill="#6E6E6E">
          <path d="M10.3309152,9.74162946 L9.31194196,10.7544643 C9.2341886,10.8322177 9.14211363,10.8710938 9.03571429,10.8710938 C8.92931494,10.8710938 8.83723997,10.8322177 8.75948661,10.7544643 L5.5,7.49497768 L2.24051339,10.7544643 C2.16276003,10.8322177 2.07068506,10.8710938 1.96428571,10.8710938 C1.85788637,10.8710938 1.7658114,10.8322177 1.68805804,10.7544643 L0.669084821,9.74162946 C0.591331456,9.6638761 0.552455357,9.57077807 0.552455357,9.46233259 C0.552455357,9.35388711 0.591331456,9.26078908 0.669084821,9.18303571 L5.22377232,4.63448661 C5.30152569,4.55673324 5.39360066,4.51785714 5.5,4.51785714 C5.60639934,4.51785714 5.69847431,4.55673324 5.77622768,4.63448661 L10.3309152,9.18303571 C10.4086685,9.26078908 10.4475446,9.35388711 10.4475446,9.46233259 C10.4475446,9.57077807 10.4086685,9.6638761 10.3309152,9.74162946 Z" />
        </g>
      </g>
    </svg>
  );
}

export default Carot;
