import React from 'react';


function Upload() {
  return (
    <svg width="661px" height="661px" viewBox="0 0 661 661" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="-1" y="-3" width="663" height="666" />
        <g transform="translate(24.000000, 93.000000)">
          <g transform="translate(0.000000, 0.839000)">
            <path d="M346.710514,38.2658604 L345.173487,39.7997407 C335.186957,49.7658269 329.54353,62.0135505 328.280253,74.8901232 L270.5,45.9999968 L41,160.749997 L41,385.410603 L270.5,500.160603 L500,385.410603 L500,271.161 L519.502993,271.161 C526.971566,271.161 534.227355,269.87082 541,267.322757 L541,410.749997 L270.5,545.999997 L0,410.749997 L0,135.410603 L270.5,0.160603231 L346.710514,38.2658604 Z" fill="#000000" fillRule="nonzero" />
            <path d="M411.161678,180.829764 L411.161678,212.859113 C411.161678,217.328082 411.624595,221.720543 412.543058,225.978468 L291,286.749997 L291,524.0803 L250,524.0803 L250,261.410603 L411.161678,180.829764 Z" fill="#000000" fillRule="nonzero" />
            <path d="M270.5,274.0803 L20.5,149.0803" stroke="#000000" strokeWidth="41" />
          </g>
          <path d="M330.476475,99.3324995 C331.068355,101.363029 331.766766,103.385694 332.571846,105.394271 C337.32451,117.251593 345.121071,126.694968 354.931743,133.098836 L152.835685,229.320942 C142.613368,234.187999 130.381014,229.846702 125.513958,219.624385 C120.646901,209.402068 124.988198,197.169714 135.210515,192.302658 L330.476475,99.3324995 Z" fill="#000000" fillRule="nonzero" />
        </g>
        <polygon fill="#000000" points="114.003081 479.084644 146.075694 495.194982 165.413593 435.308568 184.751493 514.622154 216.824106 530.732491 252.827187 407.817135 214.937482 388.784824 199.372831 448.166572 181.607119 371.642709 149.848943 355.690315 132.08323 414.366452 116.51858 339.3482 78 320" />
        <path d="M638.452092,165.075472 L530.230539,57.0754717 C527.507979,54.3584854 523.764476,53 519,53 C514.235524,53 510.492021,54.3584854 507.769461,57.0754717 L399.547908,165.075472 C394.783432,169.830194 393.762473,175.603768 396.485034,182.396226 C399.207594,189.188684 404.312384,192.584906 411.799404,192.584906 L478.161678,192.584906 L478.161678,306.698113 C478.161678,311.452835 479.693115,315.358491 482.755989,318.415094 C485.818863,321.471698 489.732531,323 494.497007,323 L543.502993,323 C548.267469,323 552.181137,321.471698 555.244011,318.415094 C558.306885,315.358491 559.838322,311.452835 559.838322,306.698113 L559.838322,192.584906 L626.200596,192.584906 C633.687616,192.584906 638.792406,189.188684 641.514966,182.396226 C644.237527,175.603768 643.216568,169.830194 638.452092,165.075472 Z" fill="#000000" fillRule="nonzero" />
      </g>
    </svg>
  );
}


export default Upload;
