import React from 'react';


function GearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12">
      <path d="M 5.9991695,3.830357 C 4.8017544,3.830357 3.8303571,4.8017545 3.8303571,6 c 0,1.1969865 0.9713973,2.170473 2.1688124,2.170473 C 7.197,8.170473 8.1612855,7.196973 8.1612855,6 8.1612725,4.8017545 7.1969865,3.830357 5.9991695,3.830357 Z M 10.347656,7.246366 9.9567455,8.1880445 10.654005,9.5574645 10.746081,9.738268 9.779277,10.705071 8.1930805,9.955915 7.251402,10.342634 6.7734375,11.805803 6.7123395,12 h -1.3665 L 4.7544643,10.3485 3.8127857,9.960107 2.4413036,10.655706 2.2605,10.746107 1.294125,9.7801475 2.0424509,8.193094 1.6544732,7.2522455 0.19378125,6.7751115 0,6.7123395 V 5.346683 L 1.6527589,4.7544645 2.0407366,3.8144465 1.3455669,2.4420935 1.2539062,2.2621205 2.2190223,1.2970045 3.8073348,2.044915 4.7477678,1.656107 5.2253035,0.193768 5.2868305,0 H 6.652902 l 0.5918035,1.6536025 0.939174,0.388808 1.371924,-0.696013 0.1824775,-0.091661 0.96596,0.965116 -0.748326,1.585795 0.386719,0.9421205 1.464843,0.4775355 L 12,5.2868305 v 1.365241 z" />
    </svg>
  );
}

export default GearIcon;
