import React from 'react';


function User({ dark }) {
  return (
    <svg width="20px" height="20px" viewBox="0 0 19.4 19.4" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path fill={dark ? '#FFFFFF' : '#3E3E3E'} d="M0,9.7c0-1.7,0.4-3.4,1.3-4.8s2.1-2.7,3.5-3.5S7.9,0,9.7,0s3.4,0.4,4.8,1.3s2.7,2.1,3.5,3.5c0.9,1.5,1.3,3.1,1.3,4.8s-0.4,3.4-1.3,4.8c-0.9,1.5-2.1,2.7-3.5,3.5c-1.5,0.9-3.1,1.3-4.8,1.3s-3.4-0.4-4.8-1.3c-1.5-0.9-2.7-2.1-3.5-3.5S0,11.4,0,9.7z M2.8,14.6c0.8,1.1,1.8,2,3,2.6s2.5,0.9,3.9,0.9s2.7-0.3,3.9-0.9s2.2-1.5,3-2.6c-0.1-0.5-0.3-1-0.6-1.4c-0.4-0.4-0.8-0.7-1.3-0.8l-1.2-0.4c-1.1,0.9-2.3,1.4-3.7,1.4S7.1,13,6,12.1l-1.2,0.4c-0.5,0.1-1,0.4-1.3,0.8S2.9,14.1,2.8,14.6z M5.3,7.8c0,0.8,0.2,1.5,0.6,2.2s0.9,1.2,1.6,1.6s1.4,0.6,2.2,0.6s1.5-0.2,2.2-0.6c0.7-0.4,1.2-0.9,1.6-1.6s0.6-1.4,0.6-2.2s-0.2-1.5-0.6-2.2S12.6,4.4,11.9,4c-0.7-0.4-1.4-0.6-2.2-0.6S8.2,3.6,7.5,4S6.3,4.9,5.9,5.6S5.3,7,5.3,7.8z" />
      </g>
    </svg>
  );
}


export default User;
