import React from 'react';
import PropTypes from 'prop-types';


const Wand = () => {
  return (
    <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1561.000000, -185.000000)" fill="#000" fillRule="nonzero">
          <g transform="translate(56.000000, 165.000000)">
            <g transform="translate(1481.000000, 10.000000)">
              <path d="M37.28125,17.3415179 L40.5513393,14.0714286 L39.3571429,12.8772321 L36.0870536,16.1473214 L37.28125,17.3415179 Z M42.0691964,13.5691964 C42.2031257,13.7031257 42.2700893,13.8705347 42.2700893,14.0714286 C42.2700893,14.2723224 42.2031257,14.4397315 42.0691964,14.5736607 L27.7165179,28.9263393 C27.5825886,29.0602685 27.4151796,29.1272321 27.2142857,29.1272321 C27.0133919,29.1272321 26.8459828,29.0602685 26.7120536,28.9263393 L24.5022321,26.7165179 C24.3683029,26.5825886 24.3013393,26.4151796 24.3013393,26.2142857 C24.3013393,26.0133919 24.3683029,25.8459828 24.5022321,25.7120536 L38.8549107,11.359375 C38.98884,11.2254458 39.156249,11.1584821 39.3571429,11.1584821 C39.5580367,11.1584821 39.7254458,11.2254458 39.859375,11.359375 L42.0691964,13.5691964 Z M27.1919643,11.9508929 L28.2857143,12.2857143 L27.1919643,12.6205357 L26.8571429,13.7142857 L26.5223214,12.6205357 L25.4285714,12.2857143 L26.5223214,11.9508929 L26.8571429,10.8571429 L27.1919643,11.9508929 Z M31.0982143,13.7589286 L33.2857143,14.4285714 L31.0982143,15.0982143 L30.4285714,17.2857143 L29.7589286,15.0982143 L27.5714286,14.4285714 L29.7589286,13.7589286 L30.4285714,11.5714286 L31.0982143,13.7589286 Z M41.4776786,19.09375 L42.5714286,19.4285714 L41.4776786,19.7633929 L41.1428571,20.8571429 L40.8080357,19.7633929 L39.7142857,19.4285714 L40.8080357,19.09375 L41.1428571,18 L41.4776786,19.09375 Z M34.3348214,11.9508929 L35.4285714,12.2857143 L34.3348214,12.6205357 L34,13.7142857 L33.6651786,12.6205357 L32.5714286,12.2857143 L33.6651786,11.9508929 L34,10.8571429 L34.3348214,11.9508929 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};


export default Wand;
