import React from 'react';


function Dat() {
  return (
    <svg width="18px" height="14px" viewBox="0 0 240 240" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: "text-top"}}>
      <polygon fill="#00c859" points="224,179.5 119.5,239.5 15,179.5 15,59.5 119.5,-0.5 224,59.5 " />
    </svg>
  );
}


export default Dat;
