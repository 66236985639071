import React from 'react';


function PlayerArrowLeft() {
  return (
    <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-605.000000, -242.000000)" fill="#CECECE" fillRule="nonzero">
          <g transform="translate(564.000000, 241.000000)">
            <path d="M56.3263456,11.1885529 L42,11.1885529 C41.4477153,11.1885529 41,10.7408376 41,10.1885529 C41,9.63626812 41.4477153,9.18855287 42,9.18855287 L56.5738644,9.18855287 L49.7143788,2.7279587 C49.3123399,2.34929905 49.2933873,1.71641779 49.6720469,1.31437885 C50.0507066,0.912339908 50.6835878,0.893387253 51.0856268,1.27204691 L59.779756,9.46059697 C59.9497623,9.6207171 60.0214131,9.80411792 60.0188595,9.99287824 C60.0459173,10.2587796 59.9457862,10.5270083 59.7324288,10.7279587 L51.0382995,18.9165088 C50.6362606,19.2951684 50.0033793,19.2762158 49.6247197,18.8741768 C49.24606,18.4721379 49.2650127,17.8392566 49.6670516,17.460597 L56.3263456,11.1885529 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}


export default PlayerArrowLeft;
