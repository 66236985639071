import React from 'react';


function PlayerArrowLeft() {
  return (
    <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-564.000000, -242.000000)" fill="#6E6E6E" fillRule="nonzero">
          <g transform="translate(564.000000, 241.000000)">
            <path d="M3.69684805,11.1885529 L10.356142,17.460597 C10.758181,17.8392566 10.7771336,18.4721379 10.398474,18.8741768 C10.0198143,19.2762158 9.38693306,19.2951684 8.98489412,18.9165088 L0.290764858,10.7279587 C0.0774073789,10.5270083 -0.0227237201,10.2587796 0.00433411006,9.99287824 C0.00178050529,9.80411792 0.0734313073,9.6207171 0.243437606,9.46059697 L8.93756686,1.27204691 C9.3396058,0.893387253 9.97248706,0.912339908 10.3511467,1.31437885 C10.7298064,1.71641779 10.7108537,2.34929905 10.3088148,2.7279587 L3.44932924,9.18855287 L18.0231936,9.18855287 C18.5754784,9.18855287 19.0231936,9.63626812 19.0231936,10.1885529 C19.0231936,10.7408376 18.5754784,11.1885529 18.0231936,11.1885529 L3.69684805,11.1885529 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}


export default PlayerArrowLeft;
