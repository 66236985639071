import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';


function Handle({ closed }) {
  return (
    <svg className={classNames('wr-handle', { closed })} width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Panel-Top" transform="translate(-346.000000, -16.000000)" fill="#A6A6A6">
          <g>
            <path d="M356.093726,24.5000107 L349.906241,24.5000107 C349.739575,24.5000107 349.624991,24.4218859 349.562492,24.2656363 C349.499992,24.1093867 349.531242,23.968762 349.656241,23.8437623 L352.749984,20.7812696 C352.812484,20.7187697 352.895817,20.6875198 352.999983,20.6875198 C353.10415,20.6875198 353.187483,20.7187697 353.249983,20.7812696 L356.343725,23.8437623 C356.468725,23.968762 356.499975,24.1093867 356.437475,24.2656363 C356.374975,24.4218859 356.260392,24.5000107 356.093726,24.5000107 Z M360,17.5000274 L360,28.5000012 C360,28.9166667 359.854167,29.2708328 359.5625,29.5624987 C359.270833,29.8541645 358.916667,29.9999976 358.5,29.9999976 L347.5,29.9999976 C347.083333,29.9999976 346.729167,29.8541645 346.4375,29.5624987 C346.145833,29.2708328 346,28.9166667 346,28.5000012 L346,17.5000274 C346,17.0833619 346.145833,16.7291958 346.4375,16.43753 C346.729167,16.1458641 347.083333,16.000031 347.5,16.000031 L358.5,16.000031 C358.916667,16.000031 359.270833,16.1458641 359.5625,16.43753 C359.854167,16.7291958 360,17.0833619 360,17.5000274 Z M358.49997,28.3125016 L358.49997,17.687527 C358.49997,17.6458606 358.479137,17.6041937 358.43747,17.5625273 C358.395804,17.5208609 358.354137,17.5000274 358.312471,17.5000274 L347.687496,17.5000274 C347.64583,17.5000274 347.604163,17.5208609 347.562496,17.5625273 C347.52083,17.6041937 347.499996,17.6458606 347.499996,17.687527 L347.499996,28.3125016 C347.499996,28.354168 347.52083,28.3958349 347.562496,28.4375013 C347.604163,28.4791677 347.64583,28.5000012 347.687496,28.5000012 L358.312471,28.5000012 C358.354137,28.5000012 358.395804,28.4791677 358.43747,28.4375013 C358.479137,28.3958349 358.49997,28.354168 358.49997,28.3125016 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}


Handle.propTypes = {
  closed: PropTypes.bool
};

export default Handle;
