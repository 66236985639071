import Application from './containers/App/App';
import routes from './routes';


export default [
  {
    component: Application,
    routes
  }
];
