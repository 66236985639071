import React from 'react';


function Refresh() {
  return (
    <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-649.000000, -241.000000)" fill="#CECECE" fillRule="nonzero">
          <g transform="translate(564.000000, 241.000000)">
            <path d="M102.001384,9.0027553 C101.830543,9.04594324 101.658852,9.04233335 101.499849,9 L95,9 C94.4477153,9 94,8.55228475 94,8 C94,7.44771525 94.4477153,7 95,7 L100.327252,7 C99.1926118,4.60747658 96.756695,3.00011926 93.9998807,3.00011926 C93.4144269,3.00011926 92.8392483,3.07177521 92.2829446,3.21198082 C89.1998664,3.98901119 86.9998807,6.7748699 86.9998807,10.0001193 C86.9998807,13.8661125 90.1338875,17.0001193 93.9998807,17.0001193 C97.1457362,17.0001193 99.8806538,14.9063834 100.731623,11.9269037 C100.883296,11.395854 101.436752,11.0883086 101.967802,11.2399816 C102.498851,11.3916547 102.806397,11.9451108 102.654724,12.4761605 C101.560076,16.3088288 98.0446093,19.0001193 93.9998807,19.0001193 C89.029318,19.0001193 84.9998807,14.970682 84.9998807,10.0001193 C84.9998807,5.85317761 87.8276909,2.27229993 91.7941677,1.27262601 C92.5099535,1.092226 93.2492895,1.00011926 93.9998807,1.00011926 C96.7967737,1.00011926 99.3362794,2.28644501 101,4.34100904 L101,1 C101,0.44771525 101.447715,0 102,0 C102.552285,0 103,0.44771525 103,1 L103,9 L102.012041,9 C102.008498,9.00093642 102.004946,9.0018549 102.001384,9.0027553 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}


export default Refresh;
