import React from 'react';

function Help() {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="02_Collections-View" transform="translate(-1236.000000, -69.000000)">
          <g id="Group-5" transform="translate(1237.000000, 70.000000)">
            <path d="M10.8923761,11.7847522 L8.52842134,11.7847522 L8.52842134,9.67537716 L9.03758082,9.67537716 C10.290281,9.67537716 10.9166218,9.23087729 10.9166218,8.34186422 C10.9166218,8.03070965 10.8075173,7.79229609 10.589305,7.62661638 C10.3710927,7.46093667 10.0902496,7.37809806 9.74676724,7.37809806 C9.03151582,7.37809806 8.59711822,7.82461838 8.44356142,8.71767241 L6.20689655,7.97211746 C6.36045335,7.17604667 6.75141119,6.56789003 7.37978179,6.14762931 C8.00815239,5.72736859 8.83148737,5.51724138 9.84981142,5.51724138 C10.9651187,5.51724138 11.852098,5.77383903 12.5107759,6.28704203 C13.1694537,6.80024502 13.4987877,7.46497437 13.4987877,8.28125 C13.4987877,9.0247882 13.2704764,9.63496529 12.813847,10.1117996 C12.3572175,10.5886338 11.7167336,10.8856406 10.8923761,11.0028287 L10.8923761,11.7847522 Z M10.8923761,14.9488147 L8.52842134,14.9488147 L8.52842134,12.8394397 L10.8923761,12.8394397 L10.8923761,14.9488147 Z" id="?" fill="#000000" />
            <circle id="Oval-5" stroke="#000000" strokeLinecap="square" cx="10" cy="10" r="10" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Help;
